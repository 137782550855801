<template>
  <div class="choiceData">
    <!-- <label @click="buttonProjectManagerFn" class="choiceDataBtn"> -->
    <label @click="dlgVisible = true" class="choiceDataBtn">
      <slot name="button"> </slot>
    </label>
    <el-dialog
      class="choiceDataCon"
      title="请选择"
      :visible.sync="dlgVisible"
      :append-to-body="true"
      width="60%"
    >
      <div class="selected-list">
        <div class="mainList">
          <div class="mainList_content">
            <div class="mainList_content_sub">
              <div class="mainList_operation_search" style="margin-bottom: 5px">
                <span style="margin-right: 8px">
                  标的名称：
                  <el-input
                    style="width: 320px; margin: 0 8px 8px 0"
                    v-model="filter.bidName"
                    placeholder="请输入标的名称"
                  >
                  </el-input>
                </span>

                <el-button
                  type="primary"
                  size="medium"
                  icon="el-icon-search"
                  @click="pageChangeHandler(1)"
                  >搜索</el-button
                >
                <el-button size="medium" type="warning" icon="el-icon-refresh" @click="handleReset">
                  清空</el-button
                >
              </div>
              <el-table
                ref="multipleTable"
                :border="true"
                :data="selectedList"
                tooltip-effect="dark"
                height="string"
                v-loading="selectedListLoad"
              >
                <slot name="tableColumn-left"></slot>

                <el-table-column label="操作" width="100" align="center" fixed="right">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="choseBidFn(scope.row)"
                      :type="isBidSelected(scope.row.id) ? 'info' : 'primary'"
                      >
                        {{  isBidSelected(scope.row.id) ? '已选' : '选择' }}
                    </el-button>
                  </template>
                </el-table-column>
                <slot name="tableColumn"></slot>
              </el-table>
              <el-pagination
                @current-change="pageChangeHandler"
                @size-change="handleSizeChange"
                :current-page="filter.pageNow"
                :page-size="filter.pageSize"
                prev-text="上一页"
                next-text="下一页"
                :total="filter.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" name="footer" class="dialog-footer">
        <el-button type="info" plain @click="dlgVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ChoiceBid',
  components: {},
  props: {
    bidInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    bidSelectId: {
      type: String,
      default: '',
    },
    choiceBids: {
      type: Array,
      default: function () {
        return []
      },
    },
    bidIdList: {
      type: Array,
      default: function () {
        return []
      },
    }
  },
  data() {
    return {
      dlgVisible: false,
      selectedListLoad: false,
      selectedList: [],
      filter: {
        pageNow: 1,
        total: 1,
        pageSize: 50,
        bidName: null,
        businessId: null,
      },

      projectList: [],
      choseBids: [],
      selectedBidIds: [],
    }
  },
  computed: {},
  watch: {
    dlgVisible(val) {
      if (val) {
        this.getBidData()
      }
    },
  },
  created() {},
  methods: {
    show(){
      this.dlgVisible = true
    },
    isBidSelected(id) {
      if(this.bidIdList.length === 0){
        return false
      }
      return this.bidIdList.some(item => item === id);
    },
    choseBidFn(row) {
      if(this.isBidSelected(row.id)){
        this.$emit('choiceDataChange', row, 'true')
        return
      }
      this.$emit('choiceDataChange', row)
    },
    async getBidData() {
      this.selectedListLoad = true
      try {
        if(this.choiceBids.length > 0){
          console.log('this.choiceBids', this.choiceBids)
          this.filter.businessId = this.choiceBids[0]?.business.id
        }
        const res = await this.$api.bid.getBidLeaderList(this.filter)
        this.selectedList = res?.data?.records
        this.filter.total = res?.data?.total
        if (this.bidInfo.id) {
          //需要在列表中加上服务器返回的投标信息
          this.selectedList.unshift(this.bidInfo)
        }
        this.selectedListLoad = false
      } catch (error) {
        this.selectedListLoad = false
      }
    },
    pageChangeHandler(val) {
      this.filter.pageNow = val
      this.getBidData()
    },
    handleSizeChange(val) {
      this.filter.pageSize = val
      this.filter.pageNow = 1
      this.getBidData()
    },
    handleReset() {
      this.filter = {
        pageNow: 1,
        pageSize: 50,
        bidName: null,
        total: 0,
      }
      this.createdDate = []
      this.getBidData()
    },
  },
}
</script>
<style scoped lang="scss">
</style>
